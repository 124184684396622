import React from 'react'
import './footer.scss'

function Footer() {
    return (
        
            <div className='footer'>
                <span>Copyright © 2021 Created by <a href='https://www.linkedin.com/in/rutul-maskar9743/'>Rutul Maskar</a></span>
            </div>
        
        
    )
}

export default Footer
